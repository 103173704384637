// @flow
import * as React from 'react'
import styled from 'styled-components'
import consecutive from 'consecutive'
import { rem } from 'polished'
import { breakpoints, mediaQuery } from '../../styles/media'
import { color } from '../../styles/theme'

import type { AreaTypes } from '../../types/enums/AreaTypes'
import type { LinkViewModel } from '../../types/LinkViewModel'

import Link from '../../components/Link'
import MediaQuery from '../../components/MediaQuery/MediaQuery'
import Ribbon from '../../components/Ribbon/Ribbon'
import { getFontSize } from '../../styles/style-helpers'

export type Props = {
  links: Array<LinkViewModel>,
  area?: AreaTypes,
}

const Container = styled.div`
  padding-bottom: ${rem(32)};
  display: ${({ theme }) =>
    theme.name === 'kids'
      ? 'none'
      : 'flex'}; /* hide Breadcrumbs on kids theme, quick'n'dirty */
  align-items: center;
  min-height: ${rem(41)};
  overflow-x: auto;
  a:last-child {
    &:after {
      display: none;
    }
  }

  @media print {
    display: none;
  }
  ${({ area }) => area === 'library' && `padding-top: 46px;}`}

  ${mediaQuery.md`
    ${({ area }) =>
      area === 'library' &&
      `
     padding-top: ${rem(96)};
    `}
  `};
`

const LeadingRectangle = styled.div`
  height: 2px;
  width: ${rem(16)};
  min-width: ${rem(16)};
  background-color: ${color.yellow};
  margin-right: ${rem(8)};
  margin-bottom: ${rem(2)};
`

const BreadcrumbLink = styled(Link)`
  font-size: ${getFontSize('tiny')};
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primary};
  letter-spacing: 1px;
  line-height: 1;
  white-space: nowrap;

  text-overflow: ellipsis;
  overflow: hidden;

  ${mediaQuery.sm`
    max-width: ${rem(250)};
  `}

  ${mediaQuery.md`
    max-width: ${rem(120)};
  `}

  ${mediaQuery.lg`
    max-width: ${rem(190)};
  `}
`

const BreadcrumbSeperator = styled.span`
  margin-left: ${rem(8)};
  margin-right: ${rem(8)};
  font-size: ${getFontSize('tiny')};
`

type State = {}

const keyGen = consecutive()

// @TODO - Remember this will inherit the Link's hover expression - get rid of it or fix Link
class Breadcrumbs extends React.Component<Props, State> {
  static displayName = 'Breadcrumbs'
  static defaultProps = {}

  render() {
    const { links, area } = this.props

    if (!links || links.length === 0) return null

    const lastLink = links[links.length - 1]

    return (
      <Ribbon marginBottom={false} roundedTop={true}>
        <Container area={area}>
          <LeadingRectangle />
          <MediaQuery query={`(min-width: ${breakpoints.md}em)`}>
            {matches =>
              matches ? (
                links.map((item, index) => {
                  return (
                    <React.Fragment key={`Breadcrumb-${keyGen()}`}>
                      <BreadcrumbLink {...item}>{item.label}</BreadcrumbLink>
                      {index < links.length - 1 && (
                        <BreadcrumbSeperator>/</BreadcrumbSeperator>
                      )}
                    </React.Fragment>
                  )
                })
              ) : (
                <BreadcrumbLink {...lastLink}>{lastLink.label}</BreadcrumbLink>
              )
            }
          </MediaQuery>
        </Container>
      </Ribbon>
    )
  }
}

export default Breadcrumbs
